.wrapForm {
  display: grid;
  color: white;
}
.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  background: red;
  color: white;
}
.wrapRadio {
  position: relative;
}
.comment {
  position: absolute;
  bottom: 0;
  left: 65px;
  font-size: 0.7rem;
}

.ECM_RadioInput {
  padding: 12px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ECM_RadioInput:hover {
  background: rgba(0, 0, 0, 0.05);
}
.ECM_RadioInput_Input {
  opacity: 0;
  width: 0;
  margin: 0;
}
.ECM_RadioInput_Input:focus + .ECM_RadioInput_DummyInput {
  border: solid 2px #333333;
  background: #eeeeee;
}
.ECM_RadioInput_Input:checked + .ECM_RadioInput_DummyInput {
  border: solid 2px #333333;
}
.ECM_RadioInput_Input:checked + .ECM_RadioInput_DummyInput::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #333333;
}
.ECM_RadioInput_DummyInput {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #fff;
  border: solid 2px #888;
}
.ECM_RadioInput_LabelText {
  margin-left: 12px;
  display: block;
  font-size: 18px;
  font-weight: bold;
}

@media only screen and (max-width: 767px) {
  .comment {
    bottom: -10px;
    left: 56px;
  }
  .ECM_RadioInput {
    padding: 16px 8px;
  }
}
