.row-module {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
}

.form {
  text-align: left;
  display: inline-block;
}

.white {
  text-align: center;
  color: #fff;
  font-size: 0.8rem;
}
