.sample {
  font-size: 10px;
  margin-top: 5px;
  color: #dcdcdc;
}

.errorInput {
  border: 2px solid red;
}

.errorLabel {
  color: red;
	font-size: 0.9rem;
}
