.common {
  text-align: center;
  background-color: #eee;
}

.td_button {
  background-color: #fff;
  padding: 0.1rem;
  border: none;
  width: 89%;
  margin: 1px 0;
  vertical-align: middle;
}
.td_button:active {
  background-color: #ff88a7;
}

.td_clicked_button {
  background-color: #ff88a7;
  padding: 0.1rem;
  border: none;
  width: 89%;
  margin: 1px 0;
  vertical-align: middle;
}

.button_disable {
  background-color: none;
  padding: 0.1rem;
  border: none;
  width: 89%;
  margin: 1px 0;
  vertical-align: middle;
}

.icon {
  margin: 0px 8px;
  font-size: small;
  font-weight: bold;
  position: relative;
  bottom: -1px;
}

.able_icon {
  color: darkviolet;
}

.clicked_icon {
  color: #fff;
}

.unable_icon {
  color: #000;
}

@media screen and (max-width: 481px) {
  .icon {
    margin: 0px 8px;
  }  

  .unable_icon {
    font-size: xx-small;
    white-space: nowrap;
  }
}

.care_icon {
  color: blue;
}

.clicked_care_icon {
  color: #fff;
}
