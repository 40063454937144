.common {
  text-align: center;
}

@media screen and (max-width: 481px) {
  .common {
    font-size: 0.8rem;
  }
}

.date {
  font-size: small;
}

.sunday {
  font-size: small;
  color: red;
}

.saturday {
  font-size: small;
  color: blue;
}
