.container {
  background-color: #fff;
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  width: 100%;
  border-radius: 0.25rem;
}

.wrap {
  display: inline-flex;
  width: 100%;
  
}
