.top_wrapper {
  padding: 3.5rem;
  width: 100%;
}

.top {
  display: flex;
  justify-content: space-evenly;
}

.arrow_button {
  background: none;
  border: none;
}


@media screen and (max-width: 481px) {
  .top {
    font-size: 0.8rem;
  }
}
